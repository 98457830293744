export const RQ_GET_BRAND_LIST_API = {
  url:'brand/list',
  method:'GET',
  auth:true,
};

export const RQ_GET_BRAND_DATA_BY_DIVISION_API = {
  url:'brand/by-division/<%= divisionId  %>',
  method:'GET',
  auth:true,
};

export const RQ_GET_BRAND_FIND_BY_ID = {
  url: 'brand/<%= id %>',
  method: 'GET',
  auth: true,
};

export const RQ_CREATE_BRAND_API = {
  url: 'brand/create',
  method: 'PUT',
  auth: true,
};

export const RQ_UPDATE_BRAND_ALL_FIELDS_API = {
  url: 'brand/update',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_BRAND_PRODUCT_DUMP_API = {
  url:'brand/dump',
  method:'GET',
  auth:true,
};
