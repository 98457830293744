export const RQ_GET_LOCATION_DATA_API = {
  url: 'location/search-by-division/<%= divisionId %>',
  method: 'GET',
  auth: true,
};
export const RQ_GET_LOCATION_BY_DIVISION_DATA_API = {
  url: 'location/search-by-division/named-squer/?divisionId=<%= divisionId %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_LOCATION_LIST_BY_DIVISION_API = {
  url: 'location/hierarchy-filter/<%= searchId  %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_LOCATION_FIND_BY_ID = {
  url: 'location/<%= id %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_LOCATION_DETAIL_FIND_BY_ID = {
  url: 'location/findLocationById/<%= id %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_LOCATION_TYPE = {
  url: 'locationtype/list',
  method: 'GET',
  auth: true,
};
export const RQ_GET_LOCATION_TYPE_LEVEL = {
  url: 'locationtype/get-location-type-level/<%= locationTypeId %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_LOCATION_LIST_BY_DIVISION_ID_AND_TYPE_ID = {
  url: 'location/get-location-list/<%= divisionId %>/<%= typeId %>',
  method: 'GET',
  auth: true,
};

export const RQ_CREATE_LOCATION_API = {
  url: 'location/create',
  method: 'PUT',
  auth: true,
};

export const RQ_UPDATE_LOCATION_ALL_FIELDS_API = {
  url: 'location/update',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_TOWN_DATA_API = {
  url: 'town/<%= id %>',
  method: 'GET',
  auth: true,
};
