import {
  ROUTE_MANAGER_AUDITOR_EXPENSE_APPROVAL_SEARCH,
} from '@app/navigations/RoutePathConstant';
import {authLoader} from '@app/navigations/loader';
import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';

const NewExpenseApprovalSearch = lazy(() =>
  import('../../../screens/NewExpenseApprovalSearch')
    .then(module => ({default: module.NewExpenseApprovalSearch})));

const NewExpenseApprovalSearchNavigation = [{
  path: ROUTE_MANAGER_AUDITOR_EXPENSE_APPROVAL_SEARCH,
  loader: authLoader,
  element: (
    <Suspense fallback={<Spin />}>
      <NewExpenseApprovalSearch />
    </Suspense>

  ),
},
];

export default NewExpenseApprovalSearchNavigation;
