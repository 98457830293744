import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_ADD_EMPLOYEE, ROUTE_EMPLOYEE} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const Employee = lazy(() =>
  import('../../../screens/Employee')
    .then(module => ({default: module.Employee})));

const AddEmployee = lazy(() =>
  import('../../../screens/Employee')
    .then(module => ({default: module.AddEmployeeScreen})));

const EmployeeNavigation = [
  {
    path: ROUTE_EMPLOYEE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <Employee />
      </Suspense>
    ),
  },
  {
    path: ROUTE_ADD_EMPLOYEE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <AddEmployee />
      </Suspense>
    ),
  },
];

export default EmployeeNavigation;
