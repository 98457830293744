import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_SECONDARY_STOCKIST_SALES_REPORT} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const SecondaryStockistSalesReport = lazy(() =>
  import('../../../screens/SecondaryStockistSalesReport')
    .then(module => ({default: module.SecondaryStockistSalesReport})));

const SecondaryStockistSalesReportNavigation = [
  {
    path: ROUTE_SECONDARY_STOCKIST_SALES_REPORT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <SecondaryStockistSalesReport />
      </Suspense>
    ),
  },
];

export default SecondaryStockistSalesReportNavigation;
