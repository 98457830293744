import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_ADD_DISTANCE_REQUEST_EMPLOYEE, ROUTE_DISTANCE_REQUEST_EMPLOYEE} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const DistanceRequestEmployee = lazy(() =>
  import('../../../screens/DistanceRequestEmployee')
    .then(module => ({default: module.DistanceRequestEmployee})));

const CreateDistanceRequestEmployee = lazy(() =>
  import('../../../screens/DistanceRequestEmployee')
    .then(module => ({default: module.CreateDistanceRequestEmployee})));

const DistanceRequestEmployeeNavigation = [
  {
    path: ROUTE_DISTANCE_REQUEST_EMPLOYEE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <DistanceRequestEmployee />
      </Suspense>
    ),
  },
  {
    path: ROUTE_ADD_DISTANCE_REQUEST_EMPLOYEE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CreateDistanceRequestEmployee />
      </Suspense>
    ),
  },
];

export default DistanceRequestEmployeeNavigation;
