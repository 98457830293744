import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_INVENTORY_REPORT} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const InventoryReport = lazy(() =>
  import('../../../screens/InventoryReport')
    .then(module => ({default: module.InventoryReport})));

const InventoryReportNavigation = [
  {
    path: ROUTE_INVENTORY_REPORT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <InventoryReport />
      </Suspense>
    ),
  },
];

export default InventoryReportNavigation;
