import {useQuery} from '@tanstack/react-query';
import {rqCreateRequest, rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {
  RQ_CREATE_TOWN_API,
  RQ_GET_TOWN_API,
  RQ_GET_TOWN_FIND_BY_ID, RQ_GET_TOWN_FOR_LOCATION,
  RQ_UPDATE_TOWN_ALL_FIELDS_API,
} from '@app/reactQuery/apis/constants/rqTownAPIConstants';

export const RQUseGetTownData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_town_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, stateId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_TOWN_API,
        null,
        certificate,
        {stateId: stateId},
        signal,
      );
    },
    ...options,
  });
};

export const RQPutCreateTown = ({town, certificate, signal}) => {
  return rqCreateRequest(
    RQ_CREATE_TOWN_API,
    JSON.stringify({...town}),
    certificate,
    null,
    signal,
  );
};

export const RQUseGetTownFindById = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_town_find_by_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, id} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_TOWN_FIND_BY_ID,
        null,
        certificate,
        {id:id},
        signal,
      );
    },
    ...options,
  });
};

export const RQPutTownUpdateAllField = async ({
  town,
  certificate,
  signal,
  id,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_TOWN_ALL_FIELDS_API,
    JSON.stringify({...town, id:id}),
    certificate,
    null,
    signal,
  );
};

export const RQUseGetTownForLocation = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_town_for_location', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId, employeeId, visitDate} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_TOWN_FOR_LOCATION,
        null,
        certificate,
        {locationId: locationId, employeeId: employeeId, visitDate: visitDate},
        signal,
      );
    },
    ...options,
  });
};
