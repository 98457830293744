// base

export const ROUTE_BASE_ROOT = '/'; // *WARN: DO NOT change this
export const ROUTE_BASE_NAME = '/'; // *WARN: DO NOT change this
export const ROUTE_WILDCARD = '*';

export const ROUTE_LOGIN = 'login';
export const ROUTE_FORGOT_PASSWORD = 'forgotpassword';
export const ROUTE_HOME = 'home';

// -- USERS
// dashboard
export const ROUTE_DASHBOARD = 'dashboard';
export const ROUTE_REVIEW_DASHBOARD = 'home/dashboard/review';

// ORGANIZATION
//BEATS
export const ROUTE_BEATS = '/home/org/beats';
export const ROUTE_EDIT_BEAT = '/home/org/beats/edit/:id';
export const ROUTE_ADD_BEAT = '/home/org/beats/add';

export const ROUTE_FIELD_STRUCTURE = 'home/reports/fieldstructure';

export const ROUTE_UPDATION_LINK = 'home/customer-updation-link';

export const ROUTE_MANAGE_HOLIDAYS = '/home/org/holidayDetails';
export const ROUTE_OVERVIEW_REPORTS = 'home/reporting/expense/overview/search';

// Leave
export const ROUTE_LEAVE_BALANCE_REPORT = '/home/report/leave-balance';
export const ROUTE_LEAVE_REPORT = '/home/report/leave';
export const ROUTE_LEAVE_DETAILS = '/home/reports/leave_details';

//EMPLOYEE
export const ROUTE_EMPLOYEE = 'home/employee';
export const ROUTE_ADD_EMPLOYEE = '/home/employee/addEmployee';
// CUSTOMERS

export const ROUTE_CML_REPORTS = '/home/reports/cml';
export const ROUTE_DML_REPORTS = '/home/reports/dml';
export const ROUTE_DML_DOCUMENT_STATUS = '/home/reports/dml-classification-document-count';
export const ROUTE_HOSPITAL_REPORT = 'home/reports/hospital';
export const ROUTE_STOCKIST = 'home/org/stockist';
export const ROUTE_ADD_STOCKIST = 'home/org/stockist/addstockist';
export const ROUTE_DOCTOR_MASTER_UPDATION = 'home/masters/doctor/search';
export const ROUTE_ADD_DOCTOR_MASTER_UPDATION = 'home/masters/doctor/search/adddoctor';
export const ROUTE_DOCTOR_360 = 'home/masters/doctor/search/doctor360/:id/edit';
export const ROUTE_DASH = 'home/masters/doctor/search/dash';
export const ROUTE_ADD_DOCTOR_UID_MASTER_UPDATION = 'home/masters/doctor/search/adddoctors';

export const ROUTE_EDIT_DOCTOR_MASTER_UPDATION = 'home/masters/doctor/search/:id/edit';
export const ROUTE_HOSPITAL_MASTER_UPDATION = 'home/masters/hospital/search';
export const ROUTE_ADD_HOSPITAL_MASTER_UPDATION = 'home/masters/hospital/search/addhospital';
export const ROUTE_EDIT_HOSPITAL_MASTER_UPDATION = 'home/masters/hospital/search/:id/edit';

export const ROUTE_EDIT_STOCKIST = 'home/org/stockist/:id/edit';
export const ROUTE_CHEMIST_MASTER_UPDATION = 'home/masters/chemist/search';
export const ROUTE_ADD_CHEMIST_MASTER_UPDATION = 'home/masters/chemist/search/addchemist';
export const ROUTE_EDIT_CHEMIST_MASTER_UPDATION = 'home/masters/chemist/search/:id/edit';

//Uploads
export const ROUTE_UPLOADS = '/home/uploads/list';

//Downloads
export const ROUTE_DOWNLOAD = '/home/adhocReport';

//SecondaryCompliance
export const ROUTE_SECONDARY_COMPLIANCE = '/home/secondary/compliance';
export const ROUTE_SECONDARY_SALES_REPORT = '/home/reports/secondary_stockist';
export const ROUTE_SECONDARY_STOCKIST_SALES_REPORT = '/home/secondary/secondary-sales';
export const ROUTE_SECONDARY_BRAND_REPORT = '/home/reports/brand-report';

// Effort Report
export const ROUTE_EFFORT_REPORT = '/home/reports/effort';

export const ROUTE_MISSED_CALL_REPORT = '/home/reports/missed_calls';

export const ROUTE_DOCTOR_VISIT_REPORT = '/home/reports/doctorvisit';

export const ROUTE_DAILY_REPORT = '/home/reports/daily';

export const ROUTE_MTP_REPORT = '/home/reports/mtp';

export const ROUTE_NON_REPORTED_USERS = '/home/reports/non_reported_users';
export const ROUTE_CHEMIST_VISIT_SUMMARY = '/home/reports/chemistVisitSummary';
export const ROUTE_DOCTOR_VISIT_SUMMARY = '/home/reports/doctorVisitSummary';
export const ROUTE_JOINT_VISIT_REPORT = '/home/reports/joint_visit_report';
export const ROUTE_RCPA_REPORT = '/home/report/rcpa_report';
export const ROUTE_RXBER_REPORT = '/home/reports/rxberReport';
export const ROUTE_BRAND_DETAILING_REPORT = '/home/report/brand_sequence';
export const ROUTE_HOSPITAL_RCPA_REPORT = '/home/report/hospital_rcpa_report';
export const ROUTE_MTP_COMPLIANCE = '/home/report/mtp_compliance';
export const ROUTE_MARKETING_ACTIVITY_REPORT = '/home/reports/marketing-activity';
export const ROUTE_UNLOCKING_HISTORY = '/home/report/unlockhistory/dump';
export const ROUTE_UNLOCKING_HISTORY_TREND = '/home/report/unlockhistory/trend';
export const ROUTE_INPUT_DISTRIBUTION_REPORT = '/home/reports/input_distribution_report';
export const ROUTE_INPUT_STOCK_REPORT = '/home/reports/inventory_stock_report';
export const ROUTE_LAST_WORKING_DAY = '/home/reporting/expense/mis/lastWorkingDay';
export const ROUTE_CONSOLIDATED_REPORTS_ = '/home/reporting/expense/mis/consolidated';

//Approvals
export const ROUTE_CHEMIST_APPROVAL = '/home/masters/chemist/approval';
export const ROUTE_VIEW_CHEMIST_APPROVAL = '/home/masters/chemist/approval/:id/view';
export const ROUTE_DOCTOR_APPROVAL = '/home/masters/doctor/approval';
export const ROUTE_LEAVE_APPROVAL = '/home/approvals/leave_approval';
export const ROUTE_VIEW_DOCTOR_APPROVAL = '/home/masters/doctor/approval/:id/view';
export const ROUTE_DISTANCE_REQUEST = 'home/org/distance-request-list';

// Masters
export const ROUTE_USER = '/home/masters/user';

export const ROUTE_LOCATION = 'home/masters/location';

export const ROUTE_ADD_LOCATION = '/home/masters/location/addlocation';

export const ROUTE_EDIT_LOCATION = '/home/masters/location/:id/edit';

export const ROUTE_BRAND = 'home/masters/brand';

export const ROUTE_ADD_BRAND = '/home/masters/brand/addbrand';

export const ROUTE_UPDATE_BRAND = '/home/masters/brand/:id/edit';

export const ROUTE_PRODUCT = 'home/masters/product';

export const ROUTE_UPDATE_PRODUCT = '/home/masters/product/:id/edit';

export const ROUTE_ADD_PRODUCT = '/home/masters/product/addproduct';

export const ROUTE_HOLIDAYS = '/home/masters/holiday';

export const ROUTE_ADD_HOLIDAYS = '/home/masters/holiday/addholiday';

export const ROUTE_EDIT_HOLIDAYS = '/home/masters/holiday/:id/edit';

export const ROUTE_DIVISION = '/home/masters/division';

export const ROUTE_ADD_DIVISION = '/home/masters/division/adddivision';

export const ROUTE_UPDATE_DIVISION = '/home/masters/division/:id/edit';

export const ROUTE_JOBROLE = '/home/masters/jobrole';

export const ROUTE_ADD_JOBROLE = '/home/masters/jobrole/addjobrole';

export const ROUTE_EDIT_JOBROLE = '/home/masters/jobrole/:id/edit';

export const ROUTE_STATE = '/home/masters/state';

export const ROUTE_UPDATE_STATE = '/home/masters/state/:id/edit';

export const ROUTE_ADD_STATE = '/home/masters/state/addstate';

export const ROUTE_TOWN = '/home/masters/town';

export const ROUTE_ADD_TOWN = '/home/masters/town/addtown';

export const ROUTE_UPDATE_TOWN = '/home/masters/town/:id/edit';
//expense
export const ROUTE_EXPENSE = '/home/reporting/expense/search';
export const ROUTE_EXPENSE_VIEW = '/home/reporting/expense/view/:employeeId/:yearMonth';
export const ROUTE_MANAGER_EXPENSE_APPROVAL_SEARCH = '/home/reporting/expense/mis/search';

export const ROUTE_MANAGER_AUDITOR_EXPENSE_APPROVAL_SEARCH = '/home/reporting/expense/auditor/search';
//Asset Management
export const ROUTE_MY_ASSEST = '/home/assets/my-assets';

//Dashboards
export const ROUTE_SPECIALITY_COVERAGE = '/dashboards/speciality-coverage';

export const ROUTE_EFFORT_HYGIENE_REPORT = '/dashboards/effort-hygiene';

export const ROUTE_RCPA_TREND_REPORT = '/dashboards/rcpa-trend';

export const ROUTE_BRAND_SPECIALITY_REPORT = '/dashboards/brand-speciality';

export const ROUTE_SALES_VS_TGT_REPORT = '/dashboards/sales-tgt';

export const ROUTE_PRI_VS_SEC_VS_RCPA_REPORT = '/dashboards/pri-sec-rcpa';

export const ROUTE_NEW_EXPENSE_VIEW = '/home/reporting/expense/new/:employeeId/:yearMonth';
export const ROUTE_ADD_SPECIAL_FARE = '/home/mis/special-fare';
export const ROUTE_ADD_YOUTUBE = '/dashboards/youtube';
export const ROUTE_INVENTORY_REPORT = '/dashboards/inventory-report';
export const ROUTE_EXCEPTION_REPORT = '/dashboards/exception-report';
export const ROUTE_DR_CATEGORY_DASHBOARD = '/dashboards/drCategoryDashboard';

//Secondary Sales
export const ROUTE_ENTRY_SEARCH = '/home/secondary/entry/search';
export const ROUTE_VIEW_STOCKIST = '/home/secondary/entry/view/:locationId/:id/:month';

export const ROUTE_UNLOCK_EXPENCE = '/home/mis/expense-unlock';

export const ROUTE_WEBINAR_DASHBOARD = '/dashboard/webinar';
export const ROUTE_WHATSAPP_DASHBOARD = '/dashboard/whatsapp';

//Distance Request
export const ROUTE_DISTANCE_REQUEST_EMPLOYEE = 'home/reporting/distance-request';
export const ROUTE_ADD_DISTANCE_REQUEST_EMPLOYEE = '/home/reporting/distance-request/add';

//Speciality-Coverage
export const ROUTE_SPECIALITY_COVERAGE_REPORT = '/home/brandreport/speciality-coverage';
export const ROUTE_DIVISION_WISE_SPECIALITY_COVERAGE_REPORT = '/home/brandReport/division-speciality-coverage';
export const ROUTE_BRANDS_SPECIALITY_REPORT = '/home/brandreport/brand-speciality';
export const ROUTE_MERIND_SPECIALITY_REPORT = '/home/brandreport/merind-speciality';
