import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_SECONDARY_BRAND_REPORT} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const SecondarySalesBrandReport = lazy(() =>
  import('../../../screens/SecondarySalesBrandReport')
    .then(module => ({default: module.SecondarySalesBrandReport})));

const SecondarySalesBrandReportNavigation = [
  {
    path: ROUTE_SECONDARY_BRAND_REPORT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <SecondarySalesBrandReport />
      </Suspense>
    ),
  },
];

export default SecondarySalesBrandReportNavigation;
