export const RQ_GET_BRAND_SPECILAITY_API = {
  url:'dashboards/get-brand-speciality/<%= divisionId %>/<%= fromDate %>/<%= toDate %>',
  method:'GET',
  auth:true,
};

export const RQ_GET_MERIND_SPECIALITY_API = {
  url:'dashboards/get-merind-speciality/<%= fromDate %>/<%= toDate %>',
  method:'GET',
  auth:true,
};
