export const RQ_GET_TOWN_API = {
  url:'town/getByStateId/<%= stateId %>',
  method:'GET',
  auth:true,
};

export const RQ_CREATE_TOWN_API = {
  url: 'town/create',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_TOWN_FIND_BY_ID = {
  url: 'town/<%= id %>',
  method: 'GET',
  auth: true,
};

export const RQ_UPDATE_TOWN_ALL_FIELDS_API = {
  url: 'town/update',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_TOWN_CATEGORY_FIND_BY_ID = {
  url: 'systemlov/by-type/<%= type %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_TOWN_FOR_LOCATION = {
  url: 'town/all/<%= locationId %>/<%= employeeId %>/<%= visitDate %>',
  method: 'GET',
  auth: true,
};
