import {useQuery} from '@tanstack/react-query';
import {rqCreateRequest, rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {
  RQ_CREATE_HOLIDAY_API,
  RQ_GET_HOLIDAYS_LIST_API,
  RQ_GET_HOLIDAY_DETAILS_FIND_BY_STATE_API,
  RQ_GET_HOLIDAY_FIND_BY_ID,
  RQ_HOLIDAY_DETAILS_BY_STATE_ID,
  RQ_UPDATE_HOLIDAY_ALL_FIELDS_API,
} from '@app/reactQuery/apis/constants';

export const RQUseGetHolidaysListData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_holidays_list_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_HOLIDAYS_LIST_API,
        null,
        certificate,
        null,
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetHolidayFindById = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_holiday_find_by_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, id} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_HOLIDAY_FIND_BY_ID,
        null,
        certificate,
        {id:id},
        signal,
      );
    },
    ...options,
  });
};

export const RQPutHolidayUpdateAllField = async ({
  holiday,
  certificate,
  signal,
  id,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_HOLIDAY_ALL_FIELDS_API,
    JSON.stringify({...holiday, id:id}),
    certificate,
    null,
    signal,
  );
};

export const RQPostCreateHoliday = ({holiday, certificate, signal}) => {
  return rqCreateRequest(
    RQ_CREATE_HOLIDAY_API,
    JSON.stringify({...holiday}),
    certificate,
    null,
    signal,
  );
};

export const RQUseGetHolidayDetailsFindByState = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_holiday_details_find_by_state_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, stateId, year} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_HOLIDAY_DETAILS_FIND_BY_STATE_API,
        null,
        certificate,
        {stateId:stateId, year:year},
        signal,
      );
    },
    ...options,
  });
};

export const RQHolidayDetailsByStateId = async ({
  certificate,
  signal,
  stateId,
  details,
}) => {
  return rqCreateRequest(
    RQ_HOLIDAY_DETAILS_BY_STATE_ID,
    JSON.stringify(details),
    certificate,
    {stateId:stateId},
    signal,
  );
};
