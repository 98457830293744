export const RQ_GET_EMPLOYEE_LIST_BY_LOCATION_OR_DIVISION_API = {
  url: 'employee/employee-list-by-location-or-division/<%= locationId %>',
  method: 'GET',
  auth: true,
};

export const RQ_CREATE_EMPLOYEE_API = {
  url: 'employee/save-user',
  method: 'POST',
  auth: true,
};

export const RQ_INACTIVE_USER_API = {
  url: 'employee/deactivate-user/<%= userName %>/<%= lastWorkingDate %>',
  method: 'PUT',
  auth: true,
};
