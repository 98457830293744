import {useQuery} from '@tanstack/react-query';
import {rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {RQ_GET_BRAND_SPECILAITY_API, RQ_GET_MERIND_SPECIALITY_API} from '@app/reactQuery/apis/constants';

export const RQUseGetBrandSpecialityData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_brand_speciality_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, divisionId, fromDate, toDate} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_BRAND_SPECILAITY_API,
        null,
        certificate,
        {divisionId: divisionId, fromDate: fromDate, toDate: toDate},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetMerindSpecialityData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_merind_speciality_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, fromDate, toDate} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_MERIND_SPECIALITY_API,
        null,
        certificate,
        {fromDate: fromDate, toDate: toDate},
        signal,
      );
    },
    ...options,
  });
};
