import React from 'react';
import {NavLink} from 'react-router-dom';
import {Typography} from 'antd';

const MenuBuilder = menus => {
  let listOfMenus = [];

  const buildMenu = menu => {
    const convertedMenu = convertToMenu(menu);
    if (menu.children && menu.children.length > 0) {
      const childrenMenus = menu.children.map(child => buildMenu(child));
      convertedMenu.children = childrenMenus;
    }
    return convertedMenu;

  };

  menus.forEach(menu => {
    const builtMenu = buildMenu(menu);
    listOfMenus.push(builtMenu);
  });

  return listOfMenus;
};

const convertToMenu = menu => {
  let target = '_self';
  if (menu.actionUrl.indexOf('/app') === 0) {
    menu.actionUrl = menu.actionUrl.replace('/app', '');
  }
  if (menu.actionUrl.indexOf('https://') === 0) {
    target = '_blank';
  }
  {return {
    key: menu.id.id,
    label: (
      <NavLink to={menu.actionUrl} target={target}>
        {generateLabel(menu)}
      </NavLink>
    ),
  };}
};

const generateLabel = menu => {
  if (menu.name && menu.name !== '') {
    return menu.name;
  } else {
    return <Typography.Title level={5}>{menu.label}</Typography.Title>;
  }
};

export default MenuBuilder;
