import React, {Suspense, lazy} from 'react';
import {ROUTE_DR_CATEGORY_DASHBOARD} from '@app/navigations/RoutePathConstant';
import {authLoader} from '@app/navigations/loader';
import {Spin} from 'antd';

const DrCategoryDashboard = lazy(() =>
  import('../../../screens/DrCategoryDashboard')
    .then(module => ({default: module.DrCategoryDashboard})));

const DrCategoryDashboardNavigation = [
  {
    path: ROUTE_DR_CATEGORY_DASHBOARD,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <DrCategoryDashboard />
      </Suspense>
    ),
  },
];

export default DrCategoryDashboardNavigation;
