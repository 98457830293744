export const getWindowDimensions = () => {
  const {innerWidth: width, innerHeight: height} = window;
  return {
    width,
    height,
  };
};

export const formatNumber = (number, precision) => {
  const num = isNaN(number) || number === null ? 0.00 : number;
  return num.toFixed(precision);
};

export const isHoUser = jobRoleId => {
  if (jobRoleId === 'jobrl00000000000000000000000000000008' ||
      jobRoleId === 'jobrl00000000000000000000000000000009' ||
      jobRoleId === 'jobrl00000000000000000000000000000011' ||
      jobRoleId === 'jobrl10000000000000000000000000000001'
  ) {
    return true;
  } else {
    return false;
  }
};
