import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_MERIND_SPECIALITY_REPORT} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const MerindSpecialityReport = lazy(() => import('../../../screens/MerindSpecialityReport')
  .then(module => ({default: module.MerindSpecialityReport})));

const MerindSpecialityReportNavigation = [
  {
    path: ROUTE_MERIND_SPECIALITY_REPORT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <MerindSpecialityReport />
      </Suspense>
    ),
  },
];

export default MerindSpecialityReportNavigation;
