import React from 'react';
import {
  SystemMultiSelectDependentObjectAttribute,
  SystemMultiSelectObjectAttribute,
  SystemSelectObjectAttribute,
  SystemSingleSelectDependentObjectAttribute,
} from './attributeTypes';
import PropTypes from 'prop-types';
import {
  MULTI_SELECT_DEPENDENT_OBJECT_ATTRIBUTE,
  MULTI_SELECT_OBJECT_ATTRIBUTE,
  SELECT_OBJECT_ATTRIBUTE,
  SINGLE_SELECT_DEPENDANT_LOCATION_LIST_ATTRIBUTE,
  SINGLE_SELECT_DEPENDANT_MIRROR_TERRITORY_ATTRIBUTE,
  SINGLE_SELECT_DEPENDENT_OBJECT_ATTRIBUTE,
} from './attributeTypes/AttributeTypeEnum';
import {
  SingleSelectDependentLocationListAttribute,
} from '@app/components/common/FormBuilder/attributeTypes/system/SingleSelectDependentLocationListAttribute';
import {
  SingleSelectDependentMirrorTerritoryAttribute,
} from '@app/components/common/FormBuilder/attributeTypes/system/SingleSelectDependentMirrorTerritoryAttribute';

const SystemAttributeCreator = ({attributeMetadata, formValues, updateDynamicFieldValue}) => {
  let formElement;

  // *IMP*: with appropriate permission (auth) only user can use SYSTEM attributes

  switch (attributeMetadata.input_type) {
    case SELECT_OBJECT_ATTRIBUTE:
      formElement = (
        <SystemSelectObjectAttribute
          attributeMetadata={attributeMetadata}
          updateDynamicFieldValue={updateDynamicFieldValue}
        />
      );
      break;
    case MULTI_SELECT_OBJECT_ATTRIBUTE:
      formElement = (
        <SystemMultiSelectObjectAttribute
          attributeMetadata={attributeMetadata}
        />
      );
      break;
    case SINGLE_SELECT_DEPENDENT_OBJECT_ATTRIBUTE:
      formElement = (
        <SystemSingleSelectDependentObjectAttribute
          attributeMetadata={attributeMetadata}
          formValues={formValues}
          updateDynamicFieldValue={updateDynamicFieldValue}
        />
      );
      break;
    case MULTI_SELECT_DEPENDENT_OBJECT_ATTRIBUTE:
      formElement = (
        <SystemMultiSelectDependentObjectAttribute
          attributeMetadata={attributeMetadata}
          formValues={formValues}
        />
      );
      break;
    case SINGLE_SELECT_DEPENDANT_LOCATION_LIST_ATTRIBUTE:
      formElement = (
        <SingleSelectDependentLocationListAttribute
          attributeMetadata={attributeMetadata}
          formValues={formValues}
        />
      );
      break;
    case SINGLE_SELECT_DEPENDANT_MIRROR_TERRITORY_ATTRIBUTE:
      formElement = (
        <SingleSelectDependentMirrorTerritoryAttribute
          attributeMetadata={attributeMetadata}
          formValues={formValues}
        />
      );
      break;
  }

  return formElement;
};

SystemAttributeCreator.propTypes = {
  attributeMetadata: PropTypes.shape({
    input_type: PropTypes.string.isRequired,
  }).isRequired,
  formValues: PropTypes.object.isRequired,
  updateDynamicFieldValue: PropTypes.func,
};

export default SystemAttributeCreator;
