import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {
  ROUTE_DIVISION_WISE_SPECIALITY_COVERAGE_REPORT,
} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const DivisionSpecialityCoverageReport = lazy(() => import('../../../screens/Brand-DivisionSpecialityCoverageReport')
  .then(module => ({default: module.DivisionSpecialityCoverageReport})));

const DivisionSpecialityCoverageReportNavigation = [
  {
    path: ROUTE_DIVISION_WISE_SPECIALITY_COVERAGE_REPORT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <DivisionSpecialityCoverageReport />
      </Suspense>
    ),
  },
];

export default DivisionSpecialityCoverageReportNavigation;
