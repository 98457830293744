export const RQ_GET_STOCKIST_DATA_BY_CODE_API = {
  url:'stockist/by-code/<%= code  %>/<%= name  %>',
  method:'GET',
  auth:true,
};
export const RQ_GET_STATUS_BY_TYPE_API = {
  url:'systemlov/by-type/<%= type  %>',
  method:'GET',
  auth:true,
};

export const RQ_GET_SPORTS_BY_TYPE_API = {
  url:'systemlov/by-type/<%= type  %>',
  method:'GET',
  auth:true,
};

export const RQ_CREATE_STOCKIST_API = {
  url: 'stockist/createStockist',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_STOCKIST_FIND_BY_ID = {
  url: 'stockist/getStockist/<%= id %>',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_UPDATE_STOCKIST_ALL_FIELDS_API = {
  url: 'stockist/updateStockist',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_SEARCH_STOCKIST_SECONDARY_LIST_BY_LOCATION_ID_AND_MONTH_API = {
  url: 'stockist/secondary-list/<%= locationId %>/<%= yearMonth %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_SECONDARY_SALES_MASTER_DETAILS_BY_DIVISION_ID_STOCKIST_ID_MONTH_API = {
  url: 'secondarysalesmaster/details/<%= divisionId %>/<%= stockistId %>/<%= yearMonth %>',
  method: 'GET',
  auth: true,
};

export const RQ_SUBMIT_SECONDARY_SALES_MASTER_API = {
  url: 'secondarysalesmaster/submit',
  method: 'POST',
  auth: true,
};

export const RQ_SECONDARY_SALES_MASTER_UPDATE_STATUS_BY_MASTER_ID_STATUS_API = {
  url: 'secondarysalesmaster/update-status/<%= masterId %>/<%= status %>',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_SECONDARY_SALES_MASTER_ZERO_CLOSING_LIST_BY_LOCATION_ID_MONTH_API = {
  url: 'secondarysalesmaster/zero-closing-list/<%= locationId %>/<%= yearMonth %>',
  method: 'GET',
  auth: true,
};

export const RQ_ENABLE_STOCKIST_API = {
  url: 'secondaryconfiguration/enable-opening/<%= employeeCode %>/<%= stockistId %>/<%= yyyyMm %>',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_MY_STOCKIST_API = {
  url:'stockist/my-stockist-list/<%= locationId  %>',
  method:'GET',
  auth:true,
};

export const RQ_GET_STOCKIST_DUMP_API = {
  url:'stockist/stockist-dump',
  method:'GET',
  auth:true,
};
