import React, {Suspense, lazy} from 'react';
import {authLoader} from '@app/navigations/loader';
import {Spin} from 'antd';
import {ROUTE_EXCEPTION_REPORT} from '@app/navigations/RoutePathConstant';

const ExceptionReport = lazy(() =>
  import('../../../screens/ExceptionReport')
    .then(module => ({default: module.ExceptionReport})));

const ExceptionReportNavigation = [
  {
    path: ROUTE_EXCEPTION_REPORT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ExceptionReport />
      </Suspense>
    ),
  },
];

export default ExceptionReportNavigation;
