import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Form, Select} from 'antd';
import {connect} from 'react-redux';
import {SystemSelectNames} from '../SystemSelectEntityEnum';
import {isArray, isEmpty} from 'lodash';
import {RQCommonListData} from '@app/reactQuery/apis/rqUIAPI';
import {selectAuth} from '@app/redux/selectors';

const SelectObjectAttribute = ({auth, attributeMetadata, updateDynamicFieldValue}) => {
  const form = Form.useFormInstance();
  const {select_entity} = attributeMetadata;
  const [searchValue, setSearchValue] = useState('');
  const selectEnum = SystemSelectNames[select_entity];

  const {data} =
        RQCommonListData(
          {
            misc: {...selectEnum?.misc || {}},
            name: selectEnum?.name,
            api: selectEnum?.api,
            // eslint-disable-next-line react/prop-types
            certificate: auth?.certificate,
            principal: auth?.principal?.id,
            locationId: auth?.user?.location?.id,
            filter: {
              ...selectEnum?.filter ||
                    (attributeMetadata?.input_search_enable ? {[attributeMetadata.input_search_field_name]: searchValue} : {}),
            },
          }, {
            enabled:  (!attributeMetadata?.input_search_enable || searchValue.length > 2),
          });

  const dependentData = (attributeMetadata?.input_search_enable && searchValue.length < 3)
    ? []
    : isArray(data)
      ? data
      : data
        ? [data]
        : [];

  const isVertical = attributeMetadata?.layout === 'vertical';

  return (
    <Form.Item
      name={[attributeMetadata.id]}
      label={attributeMetadata.label}
      wrapperCol={{
        span: isVertical
          ? ((isVertical && attributeMetadata?.vertical_value_col_span)
            ? attributeMetadata?.vertical_value_col_span
            : 24)
          : 20,
      }}
      labelCol={{
        span: isVertical ? 24 : 4,
        ...((isVertical && attributeMetadata?.vertical_label_height)
          ? {style: {height: attributeMetadata?.vertical_label_height}}
          : {}),
      }}
      rules={attributeMetadata.validation_rules}
    >
      <Select
        allowClear={true}
        mode={attributeMetadata.multiple === true ? 'multiple' : 'single'}
        filterOption={(input, option) =>
          option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
        }
        showSearch={attributeMetadata.show_search}
        placeholder={attributeMetadata?.placeholder}
        disabled={!attributeMetadata.editable}
        // onChange={obj => {
        //   const fieldLabelValue = dependentData.filter(val => val?.id === obj)[0]?.name;
        //   if (form.getFieldValue(attributeMetadata.id + '_label') !== fieldLabelValue) {
        //     form.setFieldValue(attributeMetadata.id + '_label', fieldLabelValue);
        //   }
        // }}
        onChange={ value => {
          const updatedValues = {[attributeMetadata?.id]: value};
          if (!isEmpty(attributeMetadata?.reset_child_values_on_change)) {
            attributeMetadata?.reset_child_values_on_change?.forEach(childId => {
              updatedValues[childId] = undefined;
              form.setFieldValue(childId, undefined);
            });
            updateDynamicFieldValue(updatedValues);
          } else {
            const fieldLabelValue = dependentData.filter(val => val?.id === value)[0]?.name;
            if (form.getFieldValue(attributeMetadata.id + '_label') !== fieldLabelValue) {
              form.setFieldValue(attributeMetadata.id + '_label', fieldLabelValue);
            }
          }
        }}
        onSearch={value => setSearchValue(value)}
        searchValue={searchValue}
      >
        {data?.map((object, index) => (
          <Select.Option
            value={object?.id?.id ? object.id.id : object?.id}
            key={index}
          >
            {object?.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

SelectObjectAttribute.propTypes = {
  attributeMetadata: PropTypes.shape({
    id: PropTypes.string.isRequired,
    child_id: PropTypes.string.isRequired,
    editable: PropTypes.bool,
    validation_rules: PropTypes.array,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    input_options: PropTypes.array,
    input_search_enable: PropTypes.bool,
    input_search_field_name: PropTypes.string,
    select_entity: PropTypes.string.isRequired,
    show_search: PropTypes.bool,
    layout: PropTypes.string,
    vertical_value_col_span: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    vertical_label_height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    multiple: PropTypes.bool,
    reset_child_values_on_change: PropTypes.array,
  }).isRequired,
  auth: PropTypes.shape({
    certificate: PropTypes.string.isRequired,
    principal: PropTypes.any.isRequired,
    user: PropTypes.any,
  }).isRequired,
  updateDynamicFieldValue: PropTypes.func,
};

const mapState = state => {
  const auth = selectAuth(state);
  return {auth};
};

export default connect(mapState)(SelectObjectAttribute);
