export const RQ_GET_SPECIALITY_COVERAGE_REPORTS_API = {
  url: 'dashboards/get-speciality-coverage/<%= divisionId %>/<%= fromDate %>/<%= toDate %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_DIVISION_WISE_SPECIALITY_COVERAGE_REPORTS_API = {
  url: 'dashboards/get-division-wise-speciality-coverage/<%= divisionId %>/<%= fromDate %>/<%= toDate %>',
  method: 'GET',
  auth: true,
};
