// standard
export const TEXT = 'TEXT';
export const EMAIL = 'EMAIL';
export const SELECT = 'SELECT';
export const CHECKBOX = 'CHECKBOX';
export const DATE = 'DATE';
export const NUMBER = 'NUMBER';
export const RADIO = 'RADIO';
export const TEXTAREA = 'TEXTAREA';
export const FILE = 'FILE';
export const HIDDEN = 'HIDDEN';

// system
export const SELECT_OBJECT_ATTRIBUTE = 'SELECT_OBJECT_ATTRIBUTE';
export const MULTI_SELECT_OBJECT_ATTRIBUTE = 'MULTI_SELECT_OBJECT_ATTRIBUTE';
export const SINGLE_SELECT_DEPENDENT_OBJECT_ATTRIBUTE = 'SINGLE_SELECT_DEPENDENT_OBJECT_ATTRIBUTE';
export const MULTI_SELECT_DEPENDENT_OBJECT_ATTRIBUTE = 'MULTI_SELECT_DEPENDENT_OBJECT_ATTRIBUTE';

export const SINGLE_SELECT_DEPENDANT_LOCATION_LIST_ATTRIBUTE = 'SINGLE_SELECT_DEPENDANT_LOCATION_LIST_ATTRIBUTE';
export const SINGLE_SELECT_DEPENDANT_MIRROR_TERRITORY_ATTRIBUTE = 'SINGLE_SELECT_DEPENDANT_MIRROR_TERRITORY_ATTRIBUTE';
