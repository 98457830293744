import {useQuery} from '@tanstack/react-query';
import {rqCreateRequest, rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {
  RQ_CREATE_LOCATION_API,
  RQ_GET_LOCATION_DATA_API, RQ_GET_LOCATION_DETAIL_FIND_BY_ID,
  RQ_GET_LOCATION_FIND_BY_ID,
  RQ_GET_LOCATION_LIST_BY_DIVISION_API,
  RQ_GET_LOCATION_LIST_BY_DIVISION_ID_AND_TYPE_ID, RQ_GET_LOCATION_TYPE,
  RQ_GET_TOWN_DATA_API,
  RQ_UPDATE_LOCATION_ALL_FIELDS_API,
} from '@app/reactQuery/apis/constants/rqLocationAPIConstants';

export const RQUseGetLocationByDivisionId = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_location_by_division_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, divisionId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_LOCATION_DATA_API,
        null,
        certificate,
        {divisionId: divisionId},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetLocationListByDivision = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_location_list_by_division', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, searchId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_LOCATION_LIST_BY_DIVISION_API,
        null,
        certificate,
        {searchId: searchId},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetLocationFindById = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_location_find_by_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, id} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_LOCATION_FIND_BY_ID,
        null,
        certificate,
        {id:id},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetLocationDetailFindById = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_location_detail_find_by_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, id} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_LOCATION_DETAIL_FIND_BY_ID,
        null,
        certificate,
        {id:id},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetLocationType = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_location_type', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_LOCATION_TYPE,
        null,
        certificate,
        null,
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetTownDataById = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_town_data_by_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, id} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_TOWN_DATA_API,
        null,
        certificate,
        {id: id},
        signal,
      );
    },
    ...options,
  });
};

export const RQPutCreateLocation = ({location, certificate, signal}) => {
  return rqCreateRequest(
    RQ_CREATE_LOCATION_API,
    JSON.stringify({...location}),
    certificate,
    null,
    signal,
  );
};

export const RQPutLocationUpdateAllField = async ({
  location,
  certificate,
  signal,
  id,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_LOCATION_ALL_FIELDS_API,
    JSON.stringify({...location, id:id}),
    certificate,
    null,
    signal,
  );
};

export const RQUseGetLocationListByDivisionIdAndTypeId = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_location_list_by_division_id_and_type_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, divisionId, typeId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_LOCATION_LIST_BY_DIVISION_ID_AND_TYPE_ID,
        null,
        certificate,
        {divisionId: divisionId, typeId:typeId},
        signal,
      );
    },
    ...options,
  });
};
