export const RQ_GET_SECONDARY_SALES_REPORTS_API = {
  url: 'secondarysalesmaster/stockist-wise-report/<%= locationId %>/<%= yearMonth %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_SECONDARY_SALES_BRAND_REPORTS_API = {
  url: 'secondarysalesmaster/brand-wise-report/<%= locationId %>/<%= yearMonth %>/<%= stockistId %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_SECONDARY_SALES_DUMP_REPORTS_API = {
  url: 'secondarysalesmaster/dump-report/<%= locationId %>/<%= yearMonth %>/<%= stockistId %>/<%= brandId %>/<%= productId %>',
  method: 'GET',
  auth: true,
};
