import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_SPECIALITY_COVERAGE_REPORT} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const SpecialityCoverageReport = lazy(() => import('../../../screens/Brand-SpecialityCoverageReport')
  .then(module => ({default: module.SpecialityCoverageReport})));

const SpecialityCoverageReportsNavigation = [
  {
    path: ROUTE_SPECIALITY_COVERAGE_REPORT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <SpecialityCoverageReport />
      </Suspense>
    ),
  },
];

export default SpecialityCoverageReportsNavigation;
