import {useQuery} from '@tanstack/react-query';
import {rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {
  RQ_GET_SECONDARY_SALES_BRAND_REPORTS_API, RQ_GET_SECONDARY_SALES_DUMP_REPORTS_API,
  RQ_GET_SECONDARY_SALES_REPORTS_API,
} from '@app/reactQuery/apis/constants';

export const RQUseGetSecondarySalesReportsData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_secondary_sales_reports_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId, yearMonth} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_SECONDARY_SALES_REPORTS_API,
        null,
        certificate,
        {locationId:locationId,
          yearMonth: yearMonth},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetSecondarySalesBrandWiseReportsData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_secondary_sales_reports_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId, yearMonth, stockistId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_SECONDARY_SALES_BRAND_REPORTS_API,
        null,
        certificate,
        {locationId:locationId,
          yearMonth: yearMonth,
          stockistId: stockistId},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetSecondarySalesDumpReportsData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_secondary_sales_dump_reports_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId, yearMonth, stockistId, productId, brandId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_SECONDARY_SALES_DUMP_REPORTS_API,
        null,
        certificate,
        {locationId:locationId,
          yearMonth: yearMonth,
          stockistId: stockistId,
          brandId: brandId,
          productId: productId,
        },
        signal,
      );
    },
    ...options,
  });
};
