import {DashboardNavigation} from './screensNavigation/Dashboard';
import {createBrowserRouter} from 'react-router-dom';
import {MainHome} from '../screensComponents/HomeScreen/MainHome';
import {ErrorPage} from '../components/common/ErrorPage';
import {IndexNavigation} from './screensNavigation/Index/index';
import React from 'react';
import {PublicRoute} from './PublicRoute';
import {LoginNavigation} from './screensNavigation/Login';
import {DashboardIndexNavigation} from './screensNavigation/DashboardIndex';
import {ROUTE_BASE_NAME} from './RoutePathConstant';
import {ManageHolidaysNavigation} from '@app/navigations/screensNavigation/ManageHolidays';
import {LeaveBalanceReportNavigation} from '@app/navigations/screensNavigation/LeaveBalanceReport';
import {LeaveDetailsNavigation} from '@app/navigations/screensNavigation/LeaveDetails';
import {DMLReportNavigation} from '@app/navigations/screensNavigation/DMLReport';
import {HospitalReportNavigation} from '@app/navigations/screensNavigation/HospitalReport';
import {StockistNavigation} from '@app/navigations/screensNavigation/Stockiest';
import {DMLDocumentStatusNavigation} from '@app/navigations/screensNavigation/DMLDocumentStatus';
import {FieldStructureNavigation} from '@app/navigations/screensNavigation/FieldStructure';
import {CMLReportNavigation} from '@app/navigations/screensNavigation/CMLReport';
import {CustomerUpdationLinkNavigation} from '@app/navigations/screensNavigation/CustomerUpdationLink';
import {LeaveReportNavigation} from '@app/navigations/screensNavigation/LeaveReport';
import {UploadsNavigation} from '@app/navigations/screensNavigation/Uploads';
import {DownloadNavigation} from '@app/navigations/screensNavigation/Download';
import {SecondaryComplianceNavigation} from '@app/navigations/screensNavigation/SecondaryCompliance';
import {EffortReportNavigation} from '@app/navigations/screensNavigation/EffortReport';
import {MissedCallReportsNavigation} from '@app/navigations/screensNavigation/MissedCallReports';
import {DoctorVisitReportsNavigation} from '@app/navigations/screensNavigation/DoctorVisitReports';
import {DailyReportsNavigation} from '@app/navigations/screensNavigation/DailyReports';
import {MTPReportNavigation} from '@app/navigations/screensNavigation/MTPReport';
import {NonReportedUsersNavigation} from '@app/navigations/screensNavigation/NonReportedUsers';
import ChemistVisitSummaryNavigation
  from '@app/navigations/screensNavigation/ChemistVisitSummary/ChemistVisitSummaryNavigation';
import {DoctorVisitSummaryNavigation} from '@app/navigations/screensNavigation/DoctorVisitSummary';
import {JointVisitReportNavigation} from '@app/navigations/screensNavigation/JointVisitReport';
import chemistApprovalNavigation from '@app/navigations/screensNavigation/ChemistApproval/ChemistApprovalNavigation';
import {RCPAReportNavigation} from '@app/navigations/screensNavigation/RCPAReport';
import DoctorApprovalNavigation from '@app/navigations/screensNavigation/DoctorApproval/DoctorApprovalNavigation';
import {DistanceRequestNavigation} from '@app/navigations/screensNavigation/DistanceRequest';
import {DistanceRequestEmployeeNavigation} from '@app/navigations/screensNavigation/DistanceRequestEmployee';
import BrandDetailingNavigation from '@app/navigations/screensNavigation/BrandDetailing/BrandDetailingNavigation';
import {HospitalRCPAReportNavigation} from '@app/navigations/screensNavigation/HospitalRCPAReport';
import {MTPComplianceNavigation} from '@app/navigations/screensNavigation/MTPCompliance';
import {MarketingActivityReportNavigation} from '@app/navigations/screensNavigation/MarketingActivityReport';
import {UnlockingHistoryNavigation} from '@app/navigations/screensNavigation/UnlockingHistory';
import {UnlockingHistoryTrendNavigation} from '@app/navigations/screensNavigation/UnlockingHistoryTrend';
import {InputDistributionReportNavigation} from '@app/navigations/screensNavigation/InputDistributionReport';
import inputStockReportNavigation from '@app/navigations/screensNavigation/InputStockReport/InputStockReportNavigation';
import {LocationNavigation} from '@app/navigations/screensNavigation/Location';
import BrandNavigation from '@app/navigations/screensNavigation/Brand/BrandNavigation';
import {ProductNavigation} from '@app/navigations/screensNavigation/Product';
import {UserNavigation} from '@app/navigations/screensNavigation/User';
import {HolidaysNavigation} from '@app/navigations/screensNavigation/Holidays';
import DivisionNavigation from '@app/navigations/screensNavigation/Division/DivisionNavigation';
import jobRoleNavigation from '@app/navigations/screensNavigation/JobRole/JobRoleNavigation';
import StateNavigation from '@app/navigations/screensNavigation/State/StateNavigation';
import {TownNavigation} from '@app/navigations/screensNavigation/Town';
import {ExpenseNavigation} from '@app/navigations/screensNavigation/Expense';
import {ExpenseApprovalSearchNavigation} from '@app/navigations/screensNavigation/ExpenseApprovalSearch';
import {ExpenseSearchNavigation} from '@app/navigations/screensNavigation/ExpenseSearch';
import {DoctorMasterUpdationNavigation} from '@app/navigations/screensNavigation/DoctorMasterUpdation';
import {MyAssetNavigation} from '@app/navigations/screensNavigation/MyAsset';
import LastWorkingDayNavigation from '@app/navigations/screensNavigation/LastWorkingDay/LastWorkingDayNavigation';
import ConsolidatedReportsNavigation
  from '@app/navigations/screensNavigation/ConsolidatedReports/ConsolidatedReportsNavigation';
import OverviewNavigation from '@app/navigations/screensNavigation/Overview/OverviewNavigation';
import {ChemistMasterUpdationNavigation} from '@app/navigations/screensNavigation/ChemistMasterUpdation';
import {SpecialityCoverageReportNavigation} from '@app/navigations/screensNavigation/SpecialityCovergaeReport';
import EffortHygieneReportNavigation
  from '@app/navigations/screensNavigation/EffortHygieneReport/EffortHygieneReportNavigation';
import RcpaTrendReportNavigation from '@app/navigations/screensNavigation/RcpaTrendReport/RcpaTrendReportNavigation';
import BrandWiseSpecialityReportNavigation
  from '@app/navigations/screensNavigation/BrandWiseSpecialityReport/BrandWiseSpecialityReportNavigation';
import SalesVsTgtReportNavigation from '@app/navigations/screensNavigation/SalesVsTgtReport/SalesVsTgtReportNavigation';
import PriVsSecVsRcpaReportNavigation
  from '@app/navigations/screensNavigation/PriVsSecVsRcpaReport/PriVsSecVsRcpaReportNavigation';
import HospitalMasterUpdationNavigation
  from '@app/navigations/screensNavigation/HospitalMasterUpdation/HospitalMasterUpdationNavigation';
import {EntryNavigation} from '@app/navigations/screensNavigation/SecondarySales/EntryNavigation';
import AddSpecialFareNavigation from '@app/navigations/screensNavigation/AddSpecialFare/AddSpecialFareNavigation';
import {UnlockExpenceNavigation} from '@app/navigations/screensNavigation/UnlockExpence';
import {RxberReportNavigation} from '@app/navigations/screensNavigation/RxberReport';
import WebinarDashboardNavigation from '@app/navigations/screensNavigation/WebinarDashboard/WebinarDashboardNavigation';
import WhatsappDeliveryDashboardNavigation
  from '@app/navigations/screensNavigation/WhatsappDeliveryDashboard/WhatsappDeliveryNavigation';
import ReviewDashboardNavigation from '@app/navigations/screensNavigation/ReviewDashboard/ReviewDashboardNavigation';
import YouTubeNavigation from '@app/navigations/screensNavigation/YouTube/YouTubeNavigation';
import {SecondarySalesReportNavigation} from '@app/navigations/screensNavigation/SecondarySalesReport';
import {BeatsNavigation} from '@app/navigations/screensNavigation/Beats';
import {EditBeatNavigation} from '@app/navigations/screensNavigation/EditBeat';
import {AddBeatNavigation} from '@app/navigations/screensNavigation/AddBeat';
import LeaveApprovalNavigation from '@app/navigations/screensNavigation/LeaveApprovalNavigation/LeaveApprovalNavigation';
import {NewExpenseApprovalSearchNavigation} from '@app/navigations/screensNavigation/NewExpenseApprovalSearch';
import {InventoryReportNavigation} from '@app/navigations/screensNavigation/InventoryReport';
import {ExceptionReportNavigation} from '@app/navigations/screensNavigation/ExceptionReport';
import {SecondaryStockistSalesReportNavigation} from '@app/navigations/screensNavigation/SecondaryStockistSalesReport';
import {DrCategoryDashboardNavigation} from '@app/navigations/screensNavigation/DrCategoryDashboard';
import {EmployeeNavigation} from '@app/navigations/screensNavigation/Employee';
import {SecondarySalesBrandReportNavigation} from '@app/navigations/screensNavigation/SecondarySalesBrandReport';
import {SpecialityCoverageReportsNavigation} from '@app/navigations/screensNavigation/Brand-SpecialityCoverageReport';
import {DivisionSpecialityCoverageReportNavigation} from '@app/navigations/screensNavigation/Brand-DivisionSpecialityCoverageReport';
import {BrandSpecialityReportNavigation} from '@app/navigations/screensNavigation/BrandSpecialityReport';
import {MerindSpecialityReportNavigation} from '@app/navigations/screensNavigation/MerindSpecialityReport';

const AuthRoute = [
  ...DashboardNavigation,
  ...FieldStructureNavigation,
  ...CustomerUpdationLinkNavigation,
  ...ManageHolidaysNavigation,
  ...LeaveBalanceReportNavigation,
  ...LeaveReportNavigation,
  ...LeaveDetailsNavigation,
  ...CMLReportNavigation,
  ...DMLReportNavigation,
  ...DMLDocumentStatusNavigation,
  ...HospitalReportNavigation,
  ...StockistNavigation,
  ...UploadsNavigation,
  ...DownloadNavigation,
  ...SecondaryComplianceNavigation,
  ...EffortReportNavigation,
  ...MissedCallReportsNavigation,
  ...DoctorVisitReportsNavigation,
  ...DailyReportsNavigation,
  ...MTPReportNavigation,
  ...NonReportedUsersNavigation,
  ...ChemistVisitSummaryNavigation,
  ...DoctorVisitSummaryNavigation,
  ...JointVisitReportNavigation,
  ...chemistApprovalNavigation,
  ...RCPAReportNavigation,
  ...DoctorApprovalNavigation,
  ...DistanceRequestNavigation,
  ...BrandDetailingNavigation,
  ...HospitalRCPAReportNavigation,
  ...MTPComplianceNavigation,
  ...MarketingActivityReportNavigation,
  ...UnlockingHistoryNavigation,
  ...UnlockingHistoryTrendNavigation,
  ...InputDistributionReportNavigation,
  ...inputStockReportNavigation,
  ...LocationNavigation,
  ...BrandNavigation,
  ...ProductNavigation,
  ...UserNavigation,
  ...HolidaysNavigation,
  ...DivisionNavigation,
  ...jobRoleNavigation,
  ...StateNavigation,
  ...TownNavigation,
  ...ExpenseNavigation,
  ...ExpenseApprovalSearchNavigation,
  ...ExpenseSearchNavigation,
  ...DoctorMasterUpdationNavigation,
  ...MyAssetNavigation,
  ...LastWorkingDayNavigation,
  ...ConsolidatedReportsNavigation,
  ...OverviewNavigation,
  ...ChemistMasterUpdationNavigation,
  ...SpecialityCoverageReportNavigation,
  ...EffortHygieneReportNavigation,
  ...RcpaTrendReportNavigation,
  ...BrandWiseSpecialityReportNavigation,
  ...SalesVsTgtReportNavigation,
  ...PriVsSecVsRcpaReportNavigation,
  ...HospitalMasterUpdationNavigation,
  ...EntryNavigation,
  ...AddSpecialFareNavigation,
  ...UnlockExpenceNavigation,
  ...RxberReportNavigation,
  ...WebinarDashboardNavigation,
  ...WhatsappDeliveryDashboardNavigation,
  ...ReviewDashboardNavigation,
  ...YouTubeNavigation,
  ...SecondarySalesReportNavigation,
  ...BeatsNavigation,
  ...EditBeatNavigation,
  ...AddBeatNavigation,
  ...LeaveApprovalNavigation,
  ...ExpenseApprovalSearchNavigation,
  ...NewExpenseApprovalSearchNavigation,
  ...InventoryReportNavigation,
  ...ExceptionReportNavigation,
  ...SecondaryStockistSalesReportNavigation,
  ...DrCategoryDashboardNavigation,
  ...EmployeeNavigation,
  ...SecondarySalesBrandReportNavigation,
  ...DistanceRequestEmployeeNavigation,
  ...SpecialityCoverageReportsNavigation,
  ...DivisionSpecialityCoverageReportNavigation,
  ...BrandSpecialityReportNavigation,
  ...MerindSpecialityReportNavigation,
];

export const AppRouter = createBrowserRouter([
  IndexNavigation,
  ...LoginNavigation,
  {
    path: '',
    element: <MainHome />,
    errorElement: <ErrorPage />,
    children: [
      DashboardIndexNavigation,
      ...AuthRoute,
      ...PublicRoute,
    ],
  },
], {
  basename: ROUTE_BASE_NAME,
});
