import {useQuery} from '@tanstack/react-query';
import {rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {
  RQ_GET_DIVISION_WISE_SPECIALITY_COVERAGE_REPORTS_API,
  RQ_GET_SPECIALITY_COVERAGE_REPORTS_API,
} from '@app/reactQuery/apis/constants';

export const RQUseGetSpecialityCoverageReportsData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_speciality_coverage_reports_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, divisionId, fromDate, toDate} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_SPECIALITY_COVERAGE_REPORTS_API,
        null,
        certificate,
        {divisionId: divisionId, fromDate: fromDate, toDate: toDate},
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetDivisionWiseSpecialityCoverageReportsData = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_division_wise_speciality_coverage_reports_data', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, divisionId, fromDate, toDate} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_DIVISION_WISE_SPECIALITY_COVERAGE_REPORTS_API,
        null,
        certificate,
        {divisionId: divisionId, fromDate: fromDate, toDate: toDate},
        signal,
      );
    },
    ...options,
  });
};
